import React from "react"
import {Button, Card} from "react-bootstrap"
import scrollTo from 'gatsby-plugin-smoothscroll';
import { StaticImage } from "gatsby-plugin-image";

export default function Events(props) {


  return (
        <section>
          <h1 className="pt-5">Events</h1>
          <Card className="shadow bg-transparent border-0">
                <Card.Body><h3 className="text-light">Events</h3>
                <Card.Subtitle className="text-light mt-2">Workshop to be announced .</Card.Subtitle>
                <Card.Text className="text-light mt-2"></Card.Text>
                </Card.Body>
                <Button onClick={() => scrollTo('#contact')}>Contact Us !</Button>
          </Card>
        </section>
  )
}
